import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { PredictionModel } from './prediction-model.model';

export class ReportItem {
    claimantInfo:{
        claim:string,
        claimId: string,
        claimStatus: string,
        claimantId: string,
        claimantAge:string,
        claimantAdjustor: string,
        claimantGender: string,
        claimantStatus: string
        claimantType:string,
        injuryCode:string,
        lossState:string,
        lossCity: string,
        lossCounty: string,
        lossReserve: number,
        lawsuitStatus: string,
        claimantReportDate: Date;
        lossDate: Date;
        injuryDescription: string;
        accidentDescription: string;
        accidentType: string;
        office: string;
    }
    createdAt: firebase.firestore.Timestamp
    updatedAt: firebase.firestore.Timestamp
    docId:string
    facts:{
        predictionClaimClass:string,
        adjustedPaidLoss:number,
        claimResolvedByDay:number,
        dailyCost:number,
        injuryComparison:{
            nextUp1InjuryCode:string,
            nextUp2InjuryCode:string,
            currentInjuryCode:string
        },
        lossVenue: {
            venueType: string
        },
        claimLengthInMonths: {
            currentInjuryCode: {
                q25: number,
                q75: number
            },
            nextUp1InjuryCode:number,
            nextUp2InjuryCode:number
        }
        predictionRange:{
            max:number,
            min:number
        }
        quaterlyPaidlossTrended:number,
        similarCaseSummary:{
            paidLoss:number,
            percentageOfSimilarCases:number
        }
        medianPaidLoss:{
            currentPaidLoss:number,
            nextUp1PaidLoss:number,
            nextUp2PaidLoss:number
        },
        medianPaidLossClaimAgeNDays: {
            claimAge100DaysOrBelow: number,
            claimAge300DaysOrBelow: number,
            claimAge465DaysOrBelow: number
         } 
    };
    medicalTerms: string[];
    models: {
        gato: PredictionModel
    };
    reportTextTurns:any[]
    tools:{
        lem:{
            lemmTotal:number,
            adjuster:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
            appraiser:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
            attorney:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
            hoLegal:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
            imeMedical:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
            reconExpert:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
            surveillanceSiu:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
            xMiscOther:{
                category: string,
                max: number,
                median: number,
                min: number,
                selectedValue: number
            },
        }
    }
    reviewStatus:boolean
    uid: string

    constructor(){
        
        //declare default values for class variables
        //ask for dummy values to be passed in
    }
}